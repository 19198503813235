import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Failure } from './Failure'
import { Icon } from './Icon'
import { Loader } from './Loader'

export type FormSubmitStateProps = {
  onTryAgain?: () => void
  onSuccess: () => void
  triggerSuccessDelay?: number
  submitState: RR.RemoteResult<Error, unknown>
}

export const defaultTriggerSuccessDelay = 1500

export const FormSubmitState = (props: FormSubmitStateProps) => (
  <>
    {pipe(
      props.submitState,
      RR.fold(
        constNull,
        () => (
          <div class="absolute top left h-100 w-100 flex align-center justify-center">
            <Loader />
          </div>
        ),
        (error) => <Failure error={error} onTryAgain={props.onTryAgain} />,
        () => {
          setTimeout(
            () => props.onSuccess(),
            props.triggerSuccessDelay ?? defaultTriggerSuccessDelay,
          )
          return (
            <>
              All good <Icon intent="success" />
            </>
          )
        },
      ),
    )}
  </>
)
