import { O } from '@/std/data'
import { T } from '@/std/type'
import { JsonObject } from '@/std/types'
import { SongArtist } from './Artist'
import { SongId } from './Id'
import { SongTitle } from './Title'

export type Song = {
  id: SongId
  artist: SongArtist
  title: SongTitle
  links: string[]
  notes: O.Option<string>
  createdAt: Date
  updatedAt: Date
}

const type = T.struct({
  id: SongId,
  artist: SongArtist,
  title: SongTitle,
  links: T.array(T.string),
  notes: T.option(T.string),
  createdAt: T.DateFromString,
  updatedAt: T.DateFromString,
})

type Encoded = JsonObject
type Ord = { ord: typeof type.ord }

export const Song: T.Type<Song, Encoded> & Ord = type
