import { History, makeBrowserHistory } from '@/std/browser/history'
import { Context } from '@/std/context'
import { LogLevel, Logger } from '@/std/logger'
import { ConsoleLogger } from '@/std/logger/console'
import { AppRouter } from './router'

const [setAppContext, useAppContext, clearAppContext] =
  Context<AppContext>('AppContext')
export type AppContext = {
  logger: Logger
  history: History
}
export { useAppContext }

export type AppModel = ReturnType<typeof AppModel>
export const AppModel = () => {
  const logger = ConsoleLogger(LogLevel.Debug)
  const history = makeBrowserHistory()
  setAppContext({ logger, history })
  const router = AppRouter(history)

  return {
    dispose: clearAppContext,
    route: router.active,
    history,
  }
}
