import { RemoteResource } from '@/std/remote'
import { Api } from '../Api'
import { SongListComponent } from '../components/SongList'

export type ViewerPageInit = {
  email: string
}

export type ViewerPage = ReturnType<typeof ViewerPage>
export const ViewerPage = (init: ViewerPageInit) => {
  const api = Api({ email: init.email })
  const songbook = RemoteResource(api.get)
  return {
    email: init.email,
    songbook,
    songlist: SongListComponent({ songbook }),
  }
}
