import { Link } from '@/solidjs/router'
import { pathTo } from '../router'
import { Page } from '../ui-kit'

export const NotFound = () => (
  <Page
    contentClass="flex column"
    content={
      <main class="flex-1 flex justify-center align-center">
        <div class="vspacer-m text-center">
          <h1>404</h1>
          <p>Page not found</p>
          <p>
            Go back to{' '}
            <Link class="a" href={pathTo.home}>
              home
            </Link>
            ?
          </p>
        </div>
      </main>
    }
  />
)
