import { cx } from '@/std/classNames'
import { ComponentProps, splitProps } from 'solid-js'
import { useAppContext } from '../App'

type Props = ComponentProps<'div'> & {
  error: Error
  summary?: string
  onTryAgain?: () => void
}

export const Failure = (props: Props) => {
  const { logger } = useAppContext()
  const [, rest] = splitProps(props, ['error', 'summary', 'onTryAgain'])
  logger.error(props.error)
  return (
    <div {...rest} class={cx('vspacer-m', props.class)}>
      <h3>Unexpected error {props.error.message}</h3>
      {props.onTryAgain && (
        <div>
          <button class="button" onClick={props.onTryAgain}>
            Try again
          </button>
        </div>
      )}

      <details class="vspacer-m">
        <summary>{props.summary ?? 'Details'}</summary>

        <p style={{ 'word-wrap': 'break-word' }}>{props.error.message}</p>

        {props.error.stack && (
          <pre
            class="box inverted px-m py-l vspacer-m x-scrollauto y-scrollauto"
            style="max-height: 15rem;"
          >
            {props.error.stack.split('\n').map((line) => (
              <code class="nowrap">{line}</code>
            ))}
          </pre>
        )}
      </details>
    </div>
  )
}
