import { Context } from '@/std/context'
import { R } from '@/std/data'
import { parseJson } from '@/std/data/json'
import { flow, pipe } from '@/std/function'
import { RR, RemoteResource, TR } from '@/std/remote'
import { Songbook } from '@songbook/Songbook'
import { Song, SongId } from '@songbook/song'
import { Api } from './Api'

export const [setEditorContext, useEditorContext, clearEditorContext] =
  Context<EditorContext>('Editor')

export type EditorContext = ReturnType<typeof EditorContext>
export type EditorContextInit = { password: string; api: Api }
export const EditorContext = (init: EditorContextInit) => {
  const songbook = RemoteResource(init.api.get)
  return {
    songbook,
    refetch: songbook.fetch,
    patch: (song: Song) =>
      pipe(
        init.api.put(init.password, {
          ...RR.unwrap(songbook()),
          [song.id]: song,
        }),
        TR.tap(() => songbook.mutate((prev) => ({ ...prev, [song.id]: song }))),
      ),
    remove: (songId: SongId) => {
      const { [songId]: _, ...db } = RR.unwrap(songbook())
      return pipe(
        init.api.put(init.password, db),
        TR.tap(() => songbook.mutate(() => db)),
      )
    },
    import: flow(
      parseJson,
      R.flatMap(Songbook.decode),
      TR.fromResult,
      TR.flatMap((db) =>
        pipe(
          init.api.put(init.password, db),
          TR.tap(() => songbook.mutate(() => db)),
        ),
      ),
    ),
  }
}
