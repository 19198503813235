import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { CreateSongbookForm } from '../components/CreateSongbookForm.solid'
import { pathTo } from '../router'
import { Page } from '../ui-kit'
import { HomePage } from './home'

export const Home = (props: { model: HomePage }) => {
  const page = props.model
  const foreignEmail = solidState(page.form.foreignEmail)
  const email = solidState(page.form.email)
  const pwd = solidState(page.form.pwd)
  return (
    <Page
      content={
        <div class="main p-xl vspacer-xl">
          <div class="box p-xl vspacer-l">
            <div class="vspacer-m text-center">
              <h2>Log in</h2>
              <h4>We are glad to have you(r) back buddy 🤗</h4>
            </div>
            <div class="vspacer-l">
              <label class="block vspacer-s">
                <b class="block">Email</b>
                <input
                  class="input"
                  value={email()}
                  onInput={(e) => email.set(e.currentTarget.value)}
                />
              </label>
              <label class="block vspacer-s">
                <b class="block">Password</b>
                <input
                  class="input"
                  type="password"
                  value={pwd()}
                  onInput={(e) => pwd.set(e.currentTarget.value)}
                />
              </label>
              <div class="text-center">
                <Link
                  class="bold button w-100"
                  style="line-height: 1.5;"
                  href={pathTo.editor({ email: email(), password: pwd() })}
                >
                  Log In 🔓
                </Link>
              </div>
            </div>
          </div>

          <div class="box p-xl vspacer-l">
            <div class="vspacer-m">
              <h2>Register</h2>
              <h4>Create songbook?</h4>
              <p>If you are a newbie, you can create your own songbook here</p>
              <p class="block">
                I don’t care about your email, personal data or whatever ; I
                originally developed this for myself
              </p>
            </div>
            <CreateSongbookForm class="vspacer-l" />
          </div>

          <div class="box p-xl vspacer-l">
            <div class="vspacer-m text-center">
              <h2>Are you visiting?</h2>
              <h4>Checkout someone’s songbook 👀</h4>
            </div>
            <div class="vspacer-s">
              <label class="block bold">Email</label>
              <div class="flex hspacer-m align-center justify-center">
                <input
                  class="input"
                  value={foreignEmail()}
                  onInput={(e) => foreignEmail.set(e.currentTarget.value)}
                />
                <Link
                  class="bold button nowrap"
                  style="line-height: 1.5;"
                  href={pathTo.viewer({ email: foreignEmail() })}
                >
                  Go 👀
                </Link>
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}
