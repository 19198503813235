import { O, record } from '@/std/data'
import { pipe } from '@/std/function'
import { State } from '@/std/reactivity/state'
import { RR } from '@/std/remote'
import { Songbook } from '@songbook/Songbook'
import { Song, SongArtist } from '@songbook/song'

export type SongListComponentInit = {
  songbook: State<RR.RemoteResult<any, Songbook>>
  pageSize?: number
}

export type SongListComponent = ReturnType<typeof SongListComponent>
export const SongListComponent = (init: SongListComponentInit) => {
  const search = State('')
  const pageSize = init.pageSize ?? 20
  const page = State(1)
  const displayedSong = State(O.None<Song>())
  search.onChange(() => page.set(1))
  return {
    songbook: init.songbook,
    search,
    page,
    displayedSong,
    getList: makeFilterSongs(pageSize),
  }
}

const makeFilterSongs =
  (pageSize: number) =>
  (options: {
    search: string
    songbook: RR.RemoteResult<unknown, Songbook>
    page: number
  }) => {
    const needle = options.search.toLowerCase()
    const matches = (text: string) =>
      !text || text.toLowerCase().includes(needle)
    const songbook = pipe(
      options.songbook,
      RR.unwrapOr(() => ({})),
    )
    const songs = Object.values(songbook)
      .filter((song) => matches(song.artist) || matches(song.title))
      .sort((a, b) => a.artist.localeCompare(b.artist))
      .slice((options.page - 1) * pageSize, options.page * pageSize)
      .reduce((acc, song) => {
        acc[song.artist] = [...(acc[song.artist] ?? []), song]
        return acc
      }, {} as Record<SongArtist, Song[]>)
    return record.entries(songs)
  }
