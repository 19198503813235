import { ComponentProps } from 'solid-js'

export type IconIntent = keyof typeof intents
type Props = ComponentProps<'span'> & {
  intent: IconIntent
}
export const Icon = (props: Props) => (
  <span
    {...props}
    class={`${props.class ?? ''} fas ${intents[props.intent]()}`}
  />
)

const intents = {
  add: () => 'fa-plus',
  attachment: () => 'fa-paperclip', // or -vertical
  beginning: () => 'fa-house-flag',
  back: () => 'fa-arrow-left',
  camera: () => 'fa-camera-retro',
  checkpoint: () => 'fa-flag',
  credits: () => 'fa-copyright',
  copy: () => 'fa-clone',
  cotraveler: () => 'fa-person-hiking',
  edit: () => 'fa-pen-to-square',
  end: () => 'fa-flag-checkered',
  errored: () => 'fa-xmark',
  gallery: () => 'fa-image',
  noLocation: () => 'fa-circle-exclamation',
  hasLocation: () => 'fa-location-dot',
  hereOnMap: () => 'fa-location-dot',
  home: () => 'fa-house',
  image: () => 'fa-image',
  imageList: () => 'fa-images',
  menu: () => 'fa-bars',
  next: () => 'fa-arrow-right',
  note: () => 'fa-file-pen',
  parameters: () => 'fa-sliders',
  moreAction: () => 'fa-ellipsis-vertical',
  previous: () => 'fa-arrow-left',
  profile: () => 'fa-user',
  refresh: () => 'fa-rotate',
  remove: () => 'fa-trash',
  save: () => 'fa-floppy-disk',
  settings: () => 'fa-gear',
  signOut: () => 'fa-arrow-right-from-bracket',
  spinner: () => 'fa-spinner',
  share: () => 'fa-share-nodes',
  shareTrip: () => 'fa-map-location-dot',
  success: () => 'fa-check',
  tip: () => 'fa-lightbulb',
  warn: () => 'fa-triangle-exclamation',
}
