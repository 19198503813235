import { State } from '@/std/reactivity/state'

export type HomePageInit = Record<string, never>
export type HomePage = ReturnType<typeof HomePage>
export const HomePage = (init?: HomePageInit) => {
  return {
    form: {
      email: State(''),
      pwd: State(''),
      foreignEmail: State(''),
    },
  }
}
