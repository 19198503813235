import { makeContext } from '@/solidjs/context'
import { cx } from '@/std/classNames'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { ComponentProps, Show, createSignal, splitProps } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { Except } from 'type-fest'
import { Header } from './Header'
import './Page.css'

export type PageProps = Except<ComponentProps<'div'>, 'children'> & {
  nav?: JSX.Element
  header?: JSX.Element
  content: JSX.Element
  contentClass?: string
}
export const Page = (props: PageProps) => {
  const [rootNode, setRootNode] = createSignal<Node>()
  const [, divProps] = splitProps(props, [
    'nav',
    'content',
    'contentClass',
    'header',
  ])
  return (
    <div {...divProps} class={cx('Page', divProps.class)} ref={setRootNode}>
      {props.header}
      {pipe(
        rootNode(),
        O.fromNullable,
        O.fold(constNull, (rootNode) => (
          <PageRootProvider value={rootNode}>
            <Show when={props.nav}>
              <div class="PageNav">{props.nav}</div>
            </Show>

            <div class={cx('PageContent', props.contentClass)}>
              <Header />
              {props.content}
            </div>
          </PageRootProvider>
        )),
      )}
    </div>
  )
}

const [PageRootProvider, usePageRoot] = makeContext<Node>('PageRoot')
export { usePageRoot }
