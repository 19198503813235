import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { JSX } from 'solid-js/jsx-runtime'
import { Dialog } from './Dialog'
import { Failure } from './Failure'
import { defaultTriggerSuccessDelay } from './FormSubmitState'
import { Icon } from './Icon'
import { Loader } from './Loader'

export type FormSubmitStateOverlayProps = {
  onTryAgain?: () => void
  onClose: () => void
  onSuccess: () => void
  triggerSuccessDelay?: number
  submitState: RR.RemoteResult<Error, unknown>
}

export const FormSubmitStateOverlay = (props: FormSubmitStateOverlayProps) => (
  <>
    {pipe(
      props.submitState,
      RR.fold(
        constNull,
        () => (
          <Overlay>
            <Loader skin="inverted" />
          </Overlay>
        ),
        (error) => (
          <Dialog dialogClass="p-m vspacer-m">
            <Failure error={error} onTryAgain={props.onTryAgain} />
            <hr />
            <footer class="flex justify-end">
              <button class="discrete button" onClick={props.onClose}>
                Close
              </button>
            </footer>
          </Dialog>
        ),
        () => {
          setTimeout(
            () => props.onSuccess(),
            props.triggerSuccessDelay ?? defaultTriggerSuccessDelay,
          )
          return (
            <Overlay>
              <Icon intent="success" class="h1 feedback inverted" />
            </Overlay>
          )
        },
      ),
    )}
  </>
)

const Overlay = (props: { class?: string; children: JSX.Element }) => (
  <div class="absolute backdrop opacity-6 top left w-100 h-100 flex justify-center align-center p-m">
    {props.children}
  </div>
)
