import { History } from '@/std/browser/history'
import { Router } from '@/std/browser/router'
import { EditorPage } from './pages/editor'
import { HomePage } from './pages/home'
import { ViewerPage } from './pages/viewer'

export type Route = HomeRoute | ViewerRoute | EditorRoute

export type HomeRoute = { route: 'home'; model: HomePage }
export type ViewerRoute = { route: 'viewer'; model: ViewerPage }
export type EditorRoute = { route: 'editor'; model: EditorPage }

export const AppRouter = (history: History) =>
  Router({ history, isSameRoute })({
    '/': () => ({ route: 'home', model: HomePage({}) }),
    '/book/:email': ({ params }) => ({
      route: 'viewer',
      model: ViewerPage({ email: params.email }),
    }),
    '/book/:email/:password': ({ params }) => ({
      route: 'editor',
      model: EditorPage({
        email: params.email,
        password: params.password,
      }),
    }),
  })

export const pathTo = {
  home: '/',
  viewer: (options: { email: string }) => `/book/${options.email}`,
  editor: ({ email, password }: { email: string; password: string }) =>
    `/book/${email}/${encodeURIComponent(window.btoa(password))}`,
}

export const goTo = (path: string, method: 'push' | 'replace' = 'push') =>
  history[method](path)

const isSameRoute = (a: Route, b: Route) => {
  switch (a.route) {
    case 'home':
      return b.route === 'home'
    case 'editor':
      return (
        b.route === 'editor' &&
        a.model.email === b.model.email &&
        a.model.password === b.model.password
      )
    case 'viewer':
      return b.route === 'viewer' && a.model.email === b.model.email
  }
}
