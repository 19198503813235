import { createRemoteAction } from '@/solidjs/reactivity'
import { TR } from '@/std/remote'
import { JSX } from 'solid-js'
import { FormSubmitStateOverlay } from './FormSubmitStateOverlay'

export type ConfirmProps<E, T> = {
  heading?: JSX.Element
  message: JSX.Element
  confirmLabel?: JSX.Element
  cancelLabel?: JSX.Element
  action: () => TR.TaskResult<E, T>
  onSuccess: () => void
  onCancel: () => void
}

export const Confirm = <E extends Error, T>(props: ConfirmProps<E, T>) => {
  const [state, run, reset] = createRemoteAction(() => props.action())

  return (
    <div>
      {props.heading && <div class="p-m">{props.heading}</div>}
      {props.heading && <hr />}

      <div class="p-m flex align-center" style={{ 'min-height': '4.5rem' }}>
        <div class="flex-1">
          <FormSubmitStateOverlay
            onClose={props.onCancel}
            onSuccess={props.onSuccess}
            submitState={state()}
            onTryAgain={reset}
          />
          {props.message}
        </div>
      </div>

      <hr />

      <div class="hspacer-m flex justify-end p-m">
        <button class="discrete button" onClick={props.onCancel}>
          {props.cancelLabel ?? 'Cancel'}
        </button>
        <button class="button" onClick={run}>
          {props.confirmLabel ?? 'Confirm'}
        </button>
      </div>
    </div>
  )
}
