import { O, string } from '@/std/data'
import { pipe } from '@/std/function'
import { State } from '@/std/reactivity'
import { RemoteAction } from '@/std/remote'
import { uuid } from '@/std/uuid'
import { Song, SongArtist, SongId, SongTitle } from '@songbook/song'
import { useEditorContext } from '../EditorContext'

export type SongFormInit = {
  initialValues?: Pick<Song, 'artist' | 'title' | 'links' | 'notes'> &
    Partial<Pick<Song, 'id' | 'createdAt'>>
}

export type SongFormComponent = ReturnType<typeof SongFormComponent>
export const SongFormComponent = ({ initialValues }: SongFormInit) => {
  const core = useEditorContext()
  const form = {
    artist: State(initialValues?.artist ?? ''),
    title: State(initialValues?.title ?? ''),
    links: State(initialValues?.links ?? ['']),
    notes: State(
      pipe(
        initialValues?.notes ?? O.None(),
        O.unwrapOr(() => ''),
      ),
    ),
  }

  const action = RemoteAction(core.patch)

  const onSubmit = (event?: Event) => {
    event?.preventDefault()
    action.trigger({
      id: initialValues?.id ?? (uuid() as SongId),
      artist: form.artist() as SongArtist,
      createdAt: initialValues?.createdAt ?? new Date(),
      updatedAt: new Date(),
      notes: pipe(form.notes(), O.fromPredicate(string.isNotEmpty)),
      title: form.title() as SongTitle,
      links: form.links(),
    })
  }

  return {
    form,
    action,
    onSubmit,
  }
}
