import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { FetchClient } from '@/std/http'
import { contract } from './contract'

export type UnlisteddbClient = ReturnType<typeof makeUnlisteddbClient>
export const makeUnlisteddbClient = (fetch: FetchClient) => ({
  get: FetchApiRouteClient(contract.getDatabase, fetch),
  put: FetchApiRouteClient(contract.putDatabase, fetch),
  delete: FetchApiRouteClient(contract.deleteDatabase, fetch),
})
