import { RouterProvider } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { ErrorBoundary, onCleanup } from 'solid-js'
import { AppModel } from './App'
import { Router } from './router.solid'

type Props = {
  model: AppModel
}

export const App = (props: Props) => {
  onCleanup(props.model.dispose)
  const route = solidState(props.model.route)

  return (
    <ErrorBoundary
      fallback={(err, reset) => {
        return (
          <div class="vspacer-m">
            <div>Error: {err?.message}</div>
            <div>
              <button class="button" onClick={reset}>
                Reset?
              </button>
            </div>
            <details>
              <summary>Detail</summary>
              <pre>{JSON.stringify(err, null, 2)}</pre>
            </details>
          </div>
        )
      }}
    >
      <RouterProvider value={props.model.history}>
        <Router route={route()} />
      </RouterProvider>
    </ErrorBoundary>
  )
}
