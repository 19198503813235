import { solidState } from '@/solidjs/state'
import { list, O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { Song } from '@songbook/song'
import { createEffect, mapArray, Show } from 'solid-js'
import { Dialog } from '../ui-kit'
import { SongListComponent } from './SongList'

type Props = {
  model: SongListComponent
  onEdit?: (song: Song) => void
  onRemove?: (song: Song) => void
}
export const SongList = (props: Props) => {
  const {
    displayedSong,
    list: filtered,
    page,
    search,
  } = adaptSongList(props.model)
  let prevNextButtonsRef: HTMLDivElement | undefined

  createEffect(() => {
    const deps = page()
    const scrollContainer = document.querySelector('.PageContent')
    if (!scrollContainer || !prevNextButtonsRef) return
    const top =
      prevNextButtonsRef.getBoundingClientRect().y + scrollContainer.scrollTop
    scrollContainer.scroll({ top, behavior: 'smooth' })
  })

  const PrevNextButtons = (props: { separator?: boolean }) => (
    <Show when={list.isNonEmpty(filtered()) || page() > 1}>
      <div class="flex hspacer-s justify-center align-center">
        <button
          class="button"
          onClick={() => page.update((c) => Math.max(c - 1, 1))}
          disabled={page() <= 1}
        >
          Previous
        </button>
        <button class="button" onClick={() => page.update((c) => c + 1)}>
          Next
        </button>
        &nbsp;
        <span>Page {page()}</span>
      </div>
      {props.separator && <hr />}
    </Show>
  )

  return (
    <div class="vspacer-l w-100">
      <div class="box p-l flex align-center hspacer-m">
        <label class="block vspacer-s flex-1">
          <b class="block">Search</b>
          <input
            type="search"
            class="input"
            value={search()}
            onInput={(e) => search.set(e.currentTarget.value)}
          />
        </label>
      </div>

      {pipe(
        displayedSong(),
        O.fold(constNull, (song) => (
          <Dialog>
            <header class="p-l">
              {song.artist} - {song.title}
            </header>
            <div class="px-l">
              <hr />
            </div>
            <pre class="body p-l" style="line-height: 1.5;">
              {O.toUndefined(song.notes)}
            </pre>
            <div class="px-l">
              <hr />
            </div>
            <footer class="p-l flex justify-end">
              <button
                class="discrete button"
                onClick={() => displayedSong.set(O.None())}
              >
                Close
              </button>
            </footer>
          </Dialog>
        )),
      )}

      <div ref={prevNextButtonsRef} class="box p-l vspacer-l overflow-visible">
        <PrevNextButtons separator />
        <Show when={list.isEmpty(filtered())}>
          <div class="text-center">{'No songs to display'}</div>
        </Show>

        <div style="max-width: 100%; overflow: auto;">
          <table class="table-striped">
            <tbody>
              {mapArray(filtered, ([artist, songs]) =>
                mapArray(
                  () => songs,
                  (song, index) => (
                    <tr>
                      {index() === 0 && (
                        <td
                          rowSpan={songs.length}
                          class="p-m v-middle bordered"
                          style="max-width: 10rem"
                        >
                          <div class="ellipsis">{artist}</div>
                        </td>
                      )}
                      <td
                        class="p-m v-middle border-right w-100"
                        classList={{
                          'border-top': index() === 0,
                          'border-bottom': index() === songs.length - 1,
                        }}
                      >
                        {song.title}
                      </td>
                      <td
                        class="p-m v-middle border-right hspacer-s nowrap text-right"
                        classList={{
                          'border-top': index() === 0,
                          'border-bottom': index() === songs.length - 1,
                        }}
                      >
                        <div
                          role="combobox"
                          class="relative z-1 inline-block overflow-visible"
                        >
                          <div
                            class="discrete icon button"
                            role="button"
                            tabIndex={0}
                            aria-disabled={
                              song.links.filter(Boolean).length === 0
                            }
                            onFocus={(e) => {
                              const combobox = e.currentTarget.parentElement
                              combobox?.classList.add('opened')
                            }}
                            onBlur={(e) => {
                              const combobox = e.currentTarget.parentElement
                              requestAnimationFrame(() => {
                                combobox?.classList.remove('opened')
                              })
                            }}
                          >
                            {'🔗'}
                          </div>
                          <div class="absolute z-2 left middle translate-self minw-100 p-s">
                            <div class="options">
                              {song.links.map((link, index) => (
                                <>
                                  {index > 0 && <hr class="light" />}
                                  <a
                                    role="option"
                                    class="text-center px-xl py-m nowrap"
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {link ? makeUrl(link).hostname : 'N/A'}
                                  </a>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                        <button
                          class="icon button"
                          onClick={() => displayedSong.set(O.Some(song))}
                          disabled={!O.toUndefined(song.notes)}
                        >
                          {'🗒️'}
                        </button>
                        {props.onEdit && (
                          <button
                            class="discrete icon button"
                            onClick={() => props.onEdit!(song)}
                          >
                            {'📝'}
                          </button>
                        )}
                        {props.onRemove && (
                          <button
                            class="discrete icon button"
                            onClick={() => props.onRemove!(song)}
                          >
                            {/* {'✖️'} */}
                            {'🚮'}
                          </button>
                        )}
                      </td>
                    </tr>
                  ),
                )(),
              )()}
            </tbody>
          </table>
        </div>
        <div></div>
        <PrevNextButtons />
      </div>
    </div>
  )
}

const makeUrl = (href: string) => {
  try {
    return new URL(href)
  } catch (e) {
    console.info('invalid href', href)
    throw e
  }
}

const adaptSongList = (component: SongListComponent) => {
  const search = solidState(component.search)
  const page = solidState(component.page)
  return {
    displayedSong: solidState(component.displayedSong),
    search,
    page,
    list: () =>
      component.getList({
        page: page(),
        search: search(),
        songbook: component.songbook(),
      }),
  }
}
