import { solidRemoteResource } from '@/solidjs/reactivity'
import { Link } from '@/solidjs/router'
import { matchErrOr } from '@/std/error'
import { pipe } from '@/std/function'
import { matchHttpCode } from '@/std/http'
import { RR } from '@/std/remote'
import { SongList } from '../components/SongList.solid'
import { pathTo } from '../router'
import { Failure, Loader, Page } from '../ui-kit'
import { ViewerPage } from './viewer'

export const Viewer = (props: { model: ViewerPage }) => {
  const { songlist, songbook } = adaptViewerPage(props.model)
  return (
    <Page
      content={
        <div class="main py-xl vspacer-l">
          <header>
            <div class="flex align-center justify-center hspacer-s pb-xl">
              <Link class="discrete button" href={pathTo.home}>
                Back
              </Link>
            </div>

            <hr />
          </header>
          <div class="flex justify-center align-center p-m">
            {pipe(
              songbook(),
              RR.fold2(
                () => <Loader />,
                matchErrOr({
                  _: (err) => (
                    <Failure error={err} onTryAgain={songbook.fetch} />
                  ),
                  HttpError: matchHttpCode({
                    _: (err) => (
                      <Failure error={err} onTryAgain={songbook.fetch} />
                    ),
                    404: () => (
                      <div class="vspacer-xl text-center">
                        <h1>Not found</h1>
                        <h3>It looks like this songbook does not exist.</h3>
                        <h3>
                          Go back to{' '}
                          <Link class="a" href={pathTo.home}>
                            home
                          </Link>
                          ?
                        </h3>
                      </div>
                    ),
                  }),
                }),
                () => <SongList model={songlist} />,
              ),
            )}
          </div>
        </div>
      }
    />
  )
}

const adaptViewerPage = (page: ViewerPage) => ({
  songbook: solidRemoteResource(page.songbook),
  songlist: page.songlist,
})
