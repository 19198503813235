import { Cd } from '@/std/codec'
import { struct } from '@/std/data'
import { cnst, lazy, pipe } from '@/std/function'
import { makeFetchClient } from '@/std/http'
import { TR } from '@/std/remote'
import { Songbook } from '@songbook/Songbook'
import { makeUnlisteddbClient } from '@unlisteddb/client'
import { config } from '../config'

interface ApiShape {
  get: () => TR.TaskResult<Error, Songbook>
  put: (password: string, content: Songbook) => TR.TaskResult<Error, void>
  delete: (password: string) => TR.TaskResult<Error, void>
}

export type Api = ReturnType<typeof Api>

type ApiOptions = {
  email: string
}

const unlisteddb = makeUnlisteddbClient(
  makeFetchClient(config.apiUrl, cnst({ mode: 'cors' })),
)

export const Api = lazy((options: ApiOptions) => {
  const { email } = options
  const params = { username: email, database: 'songbook.json' }
  return {
    put: (password, content) =>
      pipe(
        unlisteddb.put({
          params,
          body: { password, content: Songbook.encode(content) },
        }),
        TR.map(struct.lookup('body')),
      ),
    get: () =>
      pipe(
        unlisteddb.get({ params }),
        TR.map(struct.lookup('body')),
        TR.mapResult(Cd.stringifiedJson.decode),
        TR.mapResult(Songbook.decode),
      ),
    delete: (password) =>
      pipe(
        unlisteddb.delete({
          params,
          body: { password },
        }),
        TR.map(struct.lookup('body')),
      ),
  } satisfies ApiShape
})
