export {}
// const isHTMLElement = isInstanceOf(HTMLElement)
// export type FrameProps = ComponentProps<'iframe'> & {
//   children: JSX.Element
//   viewport: FrameViewport
//   watch?: Accessor<unknown>[]
// }
// export const Frame = (props: FrameProps) => {
//   const [, rest] = splitProps(props, ['viewport'])
//   const [url, setUrl] = createSignal<string>()
//   createEffect((timeoutRef?: ReturnType<typeof setTimeout>) => {
//     props.watch?.forEach(call([]))
//     const element = props.children
//     if (!isHTMLElement(element)) return
//     const set = () => {
//       const blob = new Blob([html(element.outerHTML)], { type: 'text/html' })
//       const url = URL.createObjectURL(blob)
//       setUrl(url)
//     }
//     set()
//     return undefined
//     // if (timeoutRef) clearTimeout(timeoutRef)
//     // // give time to load
//     // const loadTimeEstimate = 5000
//     // return setTimeout(set, loadTimeEstimate)
//     // if (idleCbRef) cancelIdleCallback(idleCbRef)
//     // return requestIdleCallback(set)
//   }, undefined)

//   return (
//     <iframe
//       {...rest}
//       class="box"
//       width={props.viewport.width}
//       height={props.viewport.height}
//       src={url()}
//     />
//   )
// }

// export type FramesProps = Except<FrameProps, 'viewport'> & {
//   viewports: Record<string, FrameViewport>
// }
// export const Frames = (props: FramesProps) => (
//   <div class="nowrap x-scrollauto hspacer-m p-xs">
//     {mapArray(
//       () => Object.entries(props.viewports),
//       ([name, viewport]) => (
//         <div class="box p-m vspacer-m inline-block v-top">
//           <h4>
//             {name} ({viewport.width}x{viewport.height})
//           </h4>
//           <hr />
//           <Frame viewport={viewport}>{props.children}</Frame>
//         </div>
//       ),
//     )()}
//   </div>
// )

// const html = (html: string) => `
// <!DOCTYPE html>
// <html>
//   <head>
//     <meta charset="UTF-8" />
//     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
//     <meta name="theme-color" content="#1c4879" />
//     <meta name="robots" content="noindex" />
//     <base href="${location.origin}">
//     <link rel="stylesheet" href="css/variables.css" />
//     <link rel="stylesheet" href="css/global.css" />
//     <link rel="stylesheet" href="build/main.css" />
//     <link rel="stylesheet" href="css/utility.css" />
//     <link rel="stylesheet" href="vendors/fontawesome-6.1.1/fontawesome.css" />
//     <link rel="stylesheet" href="vendors/fontawesome-6.1.1/solid.css" />
//   </head>
//   <body>
//     <div id="root">${html}</div>
//     <script></script>
//   </body>
// </html>
// `
