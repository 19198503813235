import { solidRemoteAction } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { list } from '@/std/data'
import { Index } from 'solid-js'
import { FormSubmitStateOverlay } from '../ui-kit'
import { SongFormComponent, SongFormInit } from './SongForm'

type Props = Pick<SongFormInit, 'initialValues'> & {
  onSuccess: () => void
}

export const SongForm = (props: Props) => {
  const { action, form, onSubmit } = adaptSongFormComponent(
    SongFormComponent(props),
  )

  return (
    <form onSubmit={onSubmit} class="vspacer-l relative">
      <FormSubmitStateOverlay
        onClose={action.reset}
        onSuccess={props.onSuccess}
        submitState={action.state()}
        onTryAgain={onSubmit}
      />
      <label class="block">
        <b class="block">Artist</b>
        <input
          class="input"
          value={form.artist()}
          onInput={(e) => form.artist.set(e.currentTarget.value)}
        />
      </label>

      <label class="block">
        <b class="block">Title</b>
        <input
          class="input"
          value={form.title()}
          onInput={(e) => form.title.set(e.currentTarget.value)}
        />
      </label>
      <div class="vspacer-s">
        <h6>Links</h6>
        <Index each={form.links()}>
          {(link, index) => (
            <label class="block">
              <div class="flex hspacer-m align-center pb-s">
                <b class="block">Url</b>
                <button
                  class="discrete close button"
                  type="button"
                  onClick={() =>
                    form.links.update(list.filter((_, i) => i !== index))
                  }
                />
              </div>
              <input
                class="input"
                type="url"
                value={link()}
                onInput={(e) =>
                  form.links.update((links) => {
                    links[index] = e.currentTarget.value
                  })
                }
              />
            </label>
          )}
        </Index>
        <div class="flex justify-center">
          <button
            class="button"
            type="button"
            onClick={() => form.links.update((links) => [...links, ''])}
          >
            Add
          </button>
        </div>
      </div>
      <label class="block">
        <b class="block">Notes</b>
        <textarea
          class="input"
          value={form.notes()}
          rows={10}
          onInput={(e) => form.notes.set(e.currentTarget.value)}
        />
      </label>
      <div class="text-center">
        <button class="button">Save</button>
      </div>
    </form>
  )
}

const adaptSongFormComponent = (component: SongFormComponent) => ({
  form: {
    artist: solidState(component.form.artist),
    links: solidState(component.form.links),
    notes: solidState(component.form.notes),
    title: solidState(component.form.title),
  },
  action: solidRemoteAction(component.action),
  onSubmit: component.onSubmit,
})
