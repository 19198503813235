import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'

export const contract = {
  getDatabase: ApiRouteContract('GET', '/db/:database/:username', {
    response: {
      status: 200,
      codec: C.string,
      contentType: MimeType.Text,
    },
  }),
  putDatabase: ApiRouteContract('PUT', '/db/:database/:username', {
    body: {
      contentType: MimeType.Json,
      codec: C.struct({ password: C.string, content: C.unknown }),
    },
    response: { status: 204 },
  }),
  deleteDatabase: ApiRouteContract('DELETE', '/db/:database/:username', {
    body: {
      contentType: MimeType.Json,
      codec: C.struct({ password: C.string }),
    },
    response: { status: 204 },
  }),
}
