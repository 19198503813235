import { PasswordInput, TextInput, solidFormGroup } from '@/solidjs/form'
import { solidRemoteAction } from '@/solidjs/reactivity'
import { cx } from '@/std/classNames'
import { goTo, pathTo } from '../router'
import { Control, FormSubmitStateOverlay } from '../ui-kit'
import { CreateSongbookFormComponent } from './CreateSongbookForm'

type Props = {
  class?: string
}
const adaptForm = (component: CreateSongbookFormComponent) => ({
  action: solidRemoteAction(component.action),
  controls: solidFormGroup(component.controls),
  onSubmit: component.onSubmit,
})
export const CreateSongbookForm = (props: Props) => {
  const { action, controls, onSubmit } = adaptForm(
    CreateSongbookFormComponent(),
  )

  return (
    <form class={cx(props.class, 'relative')} onSubmit={onSubmit}>
      <Control
        label="Email"
        control={<TextInput class="input" control={controls.name} />}
      />
      <Control
        label="Password"
        control={<PasswordInput class="input" control={controls.pwd} />}
      />
      <div class="text-center">
        <button
          class="bold button w-100"
          style="line-height: 1.5;"
          type="submit"
        >
          Register 🔓
        </button>
      </div>

      <FormSubmitStateOverlay
        onClose={action.reset}
        onSuccess={() =>
          goTo(
            pathTo.editor({
              email: controls.name(),
              password: controls.pwd(),
            }),
          )
        }
        submitState={action.state()}
        onTryAgain={onSubmit}
      />
    </form>
  )
}
