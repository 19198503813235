import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { RemoteAction } from '@/std/remote'
import { Api } from '../Api'

export type CreateSongbookFormComponent = ReturnType<
  typeof CreateSongbookFormComponent
>
export const CreateSongbookFormComponent = () => {
  const action = RemoteAction((email: string, pwd: string) => {
    const api = Api({ email })
    return api.put(pwd, {})
  })
  const controls = FormGroup({
    name: FormControl<string>('', [nonEmpty('Required')]),
    pwd: FormControl<string>('', [nonEmpty('Required')]),
  })
  const onSubmit = (event?: Event) => {
    event?.preventDefault()
    controls.markTouched()
    if (!controls.isValid()) return
    action.trigger(controls.name(), controls.pwd())
  }
  return {
    action,
    controls,
    onSubmit,
  }
}
