import { solidRemoteAction, solidRemoteResource } from '@/solidjs/reactivity'
import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { matchErrOr } from '@/std/error'
import { constNull, flow, pipe } from '@/std/function'
import { matchHttpCode } from '@/std/http'
import { RR } from '@/std/remote'
import { Show, onCleanup } from 'solid-js'
import { SongForm } from '../components/SongForm.solid'
import { SongList } from '../components/SongList.solid'
import { pathTo } from '../router'
import {
  Dialog,
  Failure,
  FormSubmitStateOverlay,
  Loader,
  Page,
} from '../ui-kit'
import { EditorPage } from './editor'

export const Editor = (props: { model: EditorPage }) => {
  onCleanup(props.model.dispose)
  const {
    songbook,
    asViewer,
    importAction,
    importValue,
    isImportOpened,
    isNewSongFormOpened,
    removeAction,
    songToDelete,
    songToEdit,
  } = adaptEditorPage(props.model)

  return (
    <Page
      content={
        <div class="main py-xl vspacer-l">
          <header>
            <div class="flex wrap align-center justify-center gap-s pb-xl">
              <Link class="discrete button" href={pathTo.home}>
                Back
              </Link>

              <button
                class="discrete button"
                onClick={() => asViewer.update((v) => !v)}
              >
                {asViewer() ? 'As editor' : 'As viewer'}
              </button>

              <button
                class="discrete button"
                onClick={() => isNewSongFormOpened.set(true)}
              >
                New Song
              </button>

              <button
                class="discrete button"
                onClick={() => isImportOpened.set(true)}
              >
                Import
              </button>

              <button
                class="discrete button"
                onClick={props.model.downloadDump}
                disabled={!RR.isOk(songbook())}
              >
                Download
              </button>
            </div>

            <hr />
          </header>

          {pipe(
            songbook(),
            RR.fold2(
              () => <Loader />,
              matchErrOr({
                _: (err) => <Failure error={err} onTryAgain={songbook.fetch} />,
                HttpError: matchHttpCode({
                  _: (err) => (
                    <Failure error={err} onTryAgain={songbook.fetch} />
                  ),
                  404: () => (
                    <div class="vspacer-xl text-center">
                      <h1>Not found</h1>
                      <h3>It looks like this songbook does not exist.</h3>
                      <h3>
                        Go back to{' '}
                        <Link class="a" href={pathTo.home}>
                          home
                        </Link>
                        ?
                      </h3>
                    </div>
                  ),
                }),
              }),
              () => (
                <SongList
                  model={props.model.songlist}
                  onEdit={asViewer() ? undefined : flow(O.Some, songToEdit.set)}
                  onRemove={
                    asViewer() ? undefined : flow(O.Some, songToDelete.set)
                  }
                />
              ),
            ),
          )}

          <Show when={isNewSongFormOpened()}>
            <Dialog>
              <header class="p-l">
                <h3>Add a song</h3>
              </header>
              <div class="px-l">
                <hr />
              </div>
              <div class="body p-l">
                <SongForm onSuccess={() => isNewSongFormOpened.set(false)} />
              </div>
              <div class="px-l">
                <hr />
              </div>
              <footer class="p-l flex justify-end">
                <button
                  class="discrete button"
                  onClick={() => isNewSongFormOpened.set(false)}
                >
                  Close
                </button>
              </footer>
            </Dialog>
          </Show>

          {pipe(
            songToEdit(),
            O.fold(constNull, (song) => (
              <Dialog>
                <header class="p-l">
                  <h3>Edit song</h3>
                </header>
                <div class="px-l">
                  <hr />
                </div>
                <div class="body p-l">
                  <SongForm
                    initialValues={song}
                    onSuccess={() => songToEdit.set(O.None())}
                  />
                </div>
                <div class="px-l">
                  <hr />
                </div>
                <footer class="p-l flex justify-end">
                  <button
                    class="discrete button"
                    onClick={() => songToEdit.set(O.None())}
                  >
                    Close
                  </button>
                </footer>
              </Dialog>
            )),
          )}

          {pipe(
            songToDelete(),
            O.fold(constNull, (song) => (
              <Dialog dialogClass="p-m vspacer-m relative">
                <header>
                  <h3>Delete this song?</h3>
                </header>
                <hr />
                <div class="body py-l">
                  {song.artist} - {song.title}
                </div>
                <hr />
                <footer class="pt-m flex justify-end hspacer-m">
                  <button
                    class="discrete button"
                    onClick={() => songToDelete.set(O.None())}
                  >
                    Close
                  </button>
                  <button
                    class="danger button"
                    onClick={() => removeAction.trigger(song.id)}
                  >
                    Confirm
                  </button>
                </footer>

                <FormSubmitStateOverlay
                  onClose={removeAction.reset}
                  onSuccess={() => {
                    songToDelete.set(O.None())
                    removeAction.reset()
                  }}
                  submitState={removeAction.state()}
                  onTryAgain={() => removeAction.trigger(song.id)}
                />
              </Dialog>
            )),
          )}

          <Show when={isImportOpened()}>
            <Dialog dialogClass="relative">
              <FormSubmitStateOverlay
                onClose={importAction.reset}
                onSuccess={() => {
                  isImportOpened.set(false)
                  importAction.reset()
                }}
                submitState={importAction.state()}
                onTryAgain={() => importAction.trigger(importValue())}
              />
              <header class="p-l">
                <h3>Import</h3>
              </header>
              <div class="px-l">
                <hr />
              </div>
              <form
                id="import-form"
                class="body flex align-center justify-center p-l"
                onSubmit={(event) => {
                  event.preventDefault()
                  importAction.trigger(importValue())
                }}
              >
                <textarea
                  class="input"
                  rows={20}
                  value={importValue()}
                  onInput={(event) =>
                    importValue.set(event.currentTarget.value)
                  }
                />
              </form>
              <div class="px-l">
                <hr />
              </div>
              <footer class="p-l flex justify-end hspacer-l">
                <button
                  class="discrete button"
                  onClick={() => isImportOpened.set(false)}
                >
                  Close
                </button>
                <button type="submit" form="import-form" class="button">
                  Import
                </button>
              </footer>
            </Dialog>
          </Show>
        </div>
      }
    />
  )
}

const adaptEditorPage = (page: EditorPage) => ({
  songbook: solidRemoteResource(page.songbook),
  asViewer: solidState(page.asViewer),
  isNewSongFormOpened: solidState(page.isNewSongFormOpened),
  songToEdit: solidState(page.songToEdit),
  songToDelete: solidState(page.songToDelete),
  isImportOpened: solidState(page.isImportOpened),
  importValue: solidState(page.importValue),
  importAction: solidRemoteAction(page.importAction),
  removeAction: solidRemoteAction(page.removeAction),
})
