import { O } from '@/std/data'
import { State } from '@/std/reactivity'
import { RR, RemoteAction } from '@/std/remote'
import { Songbook } from '@songbook/Songbook'
import { Song } from '@songbook/song'
import { Api } from '../Api'
import {
  EditorContext,
  clearEditorContext,
  setEditorContext,
} from '../EditorContext'
import { SongListComponent } from '../components/SongList'

export type EditorPageInit = {
  email: string
  password: string
}

export type EditorPage = ReturnType<typeof EditorPage>
export const EditorPage = (init: EditorPageInit) => {
  const password = window.atob(decodeURIComponent(init.password))
  const api = Api({ email: init.email })
  const core = EditorContext({ api, password })
  setEditorContext(core)

  return {
    email: init.email,
    password: init.password,
    dispose: clearEditorContext,
    songbook: core.songbook,
    songlist: SongListComponent({ songbook: core.songbook }),
    asViewer: State(true),
    isNewSongFormOpened: State(false),
    songToEdit: State(O.None<Song>()),
    songToDelete: State(O.None<Song>()),
    isImportOpened: State(false),
    importValue: State(''),
    importAction: RemoteAction(core.import),
    removeAction: RemoteAction(core.remove),
    downloadDump: () => downloadDump(core.songbook()),
  }
}

const downloadDump = (songbook: RR.RemoteResult<unknown, Songbook>) => {
  // download a string
  const dump = songbook
  if (!RR.isOk(dump)) return
  const json = JSON.stringify(Songbook.encode(dump.value.value))
  const blob = new Blob([json], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = 'songbook.json'
  a.click()
}
